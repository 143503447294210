import { Flex, Button, Text, Stack } from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import Map from 'components/shared/Inputs/Map';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import CountryCitySelect from './Addresses/CountryCitySelect';

const LocationDetailsForm = ({
  defaultValues = {},
  onSubmit,
  isLoading,
  isUpdate,
}) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    resetField,
  } = useForm({
    defaultValues,
  });

  const watchAddress = watch('address');

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)} spacing={4} noValidate>
      <CountryCitySelect
        control={control}
        watch={watch}
        resetField={resetField}
        isUpdate={isUpdate}
        errors={errors}
        isSingleColumn
      />

      <Controller
        control={control}
        name="address"
        rules={{
          validate: (value) => {
            if (isUpdate) {
              return true;
            }

            return (
              !!(value?.city_name && value?.country_name) ||
              'thisFieldIsRequired'
            );
          },
        }}
        render={({ field: { onChange, value } }) => (
          <ErrorBoundary fallback={<Text>{t('somethingWentWrong')}</Text>}>
            <Map
              label={t('location')}
              onAddressChange={(address) => {
                onChange({
                  city_name: address.city?.long_name,
                  country_name: address.country?.long_name,
                  location: address.location,
                  name: address.name,
                  url: address.url,
                });
              }}
              defaultValue={defaultValues.address}
              error={t(errors.address?.message)}
              inputSize="lg"
              value={value}
              disabled={isUpdate}
              hideSearch={isUpdate}
              watchAddress={watchAddress}
              mapHeight="390px"
              required
            />
          </ErrorBoundary>
        )}
      />

      {!isUpdate && (
        <Flex justifyContent="flex-end">
          <Button
            colorScheme="primary"
            type="submit"
            size="lg"
            textTransform="capitalize"
            isLoading={isLoading}
          >
            {t('saveChanges')}
          </Button>
        </Flex>
      )}
    </Stack>
  );
};

export default LocationDetailsForm;
