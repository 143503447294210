import { Button } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ReactComponent as OutboundIcon } from 'assets/icons/outbound.svg';
import { createColumnHelper } from '@tanstack/react-table';
import OrderStatusTag from 'components/Dashboard/OrderStatusTag';
import StatusTag from 'components/Dashboard/StatusTag';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/date';
import ActionsButton from 'components/Dashboard/ActionsButton';
import TablePage from 'components/Dashboard/TablePage';
import useTable from 'hooks/useTable';
import { getOutbounds } from 'api/Dashboard/outbounds';
import { Link, useNavigate } from 'react-router-dom';
import { outboundTypeEnum, outboundTypeTagColors } from 'constants/dashboard';
import EmptyTable from 'components/Dashboard/EmptyTable';
import { ReactComponent as OutboundsEmptyTable } from 'assets/images/outbounds-empty-table.svg';

const StyledOutboundIcon = styled(OutboundIcon)`
  path {
    stroke: #fff;
  }
`;

const defaultSorting = [
  {
    id: 'schedule_at',
    desc: true,
  },
];

const columnHelper = createColumnHelper();

const pageFilterKey = 'q[carrier_id_in][]';

const Outbounds = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const pageFilter = [
    {
      label: t('outboundPages.allOutbounds'),
      value: '',
    },
    ...Object.keys(outboundTypeEnum).map((key) => ({
      label: t(`orderCarrier.${key}`),
      value: outboundTypeEnum[key],
    })),
  ];

  const {
    data,
    isLoading,
    selectedFilter,
    responseFilters,
    onFilterChange,
    onSortingChange,
    onPaginationChange,
    pagination,
    onSearchChange,
  } = useTable({
    fetch: getOutbounds,
    fetchKey: 'outbounds',
    pageFilterKey,
    defaultState: {
      sorting: defaultSorting,
    },
    searchKey: 'identifier_or_origin_addressable_of_Warehouse_type_name_cont',
  });

  const filters = [
    {
      label: t('status'),
      name: 'status',
      options: responseFilters.statuses?.map((status) => ({
        label: t(`orderStatus.${status}`),
        value: status,
      })),
    },
    {
      label: t('warehouse'),
      name: 'origin_id',
      options: responseFilters.warehouses?.map(([id, name]) => ({
        label: name,
        value: id,
      })),
    },
  ];

  const columns = [
    columnHelper.accessor('identifier', {
      cell: ({ getValue }) => getValue(),
      header: t('id'),
      meta: {
        mobileHeader: 'left',
      },
    }),
    columnHelper.accessor('schedule_at', {
      cell: ({ getValue }) => formatDate(getValue()),
      header: t('scheduledAt'),
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('outbound_type', {
      cell: ({ getValue }) => {
        const status = getValue();
        if (!status) {
          return null;
        }
        return (
          <StatusTag colorScheme={outboundTypeTagColors[status]}>
            {t(`orderCarrier.${status}`)}
          </StatusTag>
        );
      },
      header: t('outboundPages.outboundType'),
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('warehouse_name', {
      cell: ({ getValue }) => getValue(),
      header: t('warehouse'),
    }),
    columnHelper.accessor('destination_name', {
      cell: ({ getValue }) => getValue(),
      header: t('destination'),
    }),
    columnHelper.accessor('status', {
      cell: ({ getValue }) => <OrderStatusTag status={getValue()} />,
      header: t('status'),
      meta: {
        mobileHeader: 'right',
        fitContent: true,
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => {
        return (
          <ActionsButton
            onClick={() => {
              navigate(`/outbounds/${row.original.id}`);
            }}
          >
            {t('manage')}
          </ActionsButton>
        );
      },
      header: t('actions'),
      meta: {
        isAction: true,
      },
      enableSorting: false,
    }),
  ];

  const CreateOutboundButton = ({ fullWidth }) => {
    const { t } = useTranslation();

    return (
      <Button
        leftIcon={<StyledOutboundIcon />}
        colorScheme="primary"
        size="lg"
        as={Link}
        to="create"
        width={fullWidth && 'full'}
      >
        {t('outboundPages.requestNewOutbound')}
      </Button>
    );
  };

  return (
    <TablePage
      data={data}
      columns={columns}
      filters={filters}
      selectedFilter={selectedFilter}
      pagination={pagination}
      title={t('outboundPages.outboundOrders')}
      pageFilter={pageFilter}
      pageAction={<CreateOutboundButton />}
      onFilterChange={onFilterChange}
      onPageFilterChange={(value) => {
        onFilterChange({
          [pageFilterKey]: value,
        });
      }}
      selectedPageFilter={selectedFilter?.[pageFilterKey]}
      onSortingChange={onSortingChange}
      onPaginationChange={onPaginationChange}
      searchPlaceholder={t('outboundPages.tableSearchPlaceholder')}
      onSearchChange={onSearchChange}
      isLoading={isLoading}
      defaultSorting={defaultSorting}
      emptyTable={
        <EmptyTable
          illustration={<OutboundsEmptyTable />}
          title={t('emptyStateTitle', {
            value: t('outboundOrders'),
          })}
          subtitle={t('emptyStateSubtitle', { value: t('newOutbound') })}
          actions={<CreateOutboundButton fullWidth />}
        />
      }
    />
  );
};

export default Outbounds;
