import { createContext, useState, useContext } from 'react';

const PaymentDueContext = createContext();

export function PaymentDueProvider({ children }) {
  const [isPaymentDue, setIsPaymentDue] = useState(false);

  const updatePaymentDue = (status) => {
    setIsPaymentDue(status);
  };

  return (
    <PaymentDueContext.Provider value={{ isPaymentDue, updatePaymentDue }}>
      {children}
    </PaymentDueContext.Provider>
  );
}

export function usePaymentDue() {
  return useContext(PaymentDueContext);
}
