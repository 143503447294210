import { useState, useCallback } from 'react';

const useDebouncedCallback = (callback, delay = 500) => {
  const [timeoutId, setTimeoutId] = useState(null);

  const debouncedFunction = useCallback(
    (value) => {
      clearTimeout(timeoutId);
      const newTimeoutId = setTimeout(() => {
        callback(value);
      }, delay);
      setTimeoutId(newTimeoutId);
    },
    [callback, timeoutId, delay]
  );

  return debouncedFunction;
};

export default useDebouncedCallback;
