import {
  Stack,
  Box,
  Show,
  Flex,
  Button,
  Hide,
  SimpleGrid,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import BackToListButton from 'components/Dashboard/BackToListButton';
import { useTranslation } from 'react-i18next';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import { CloseIcon, DownloadIcon } from '@chakra-ui/icons';
import PageTitle from 'components/Layouts/DashboardLayout/PageTitle';
import { useMutation, useQuery } from 'react-query';
import StatusSection from 'components/Dashboard/Outbounds/StatusSection';
import DetailsSection from 'components/Dashboard/Outbounds/DetailsSection';
import LocationSection from 'components/Dashboard/Outbounds/LocationSection';
import SKUsSection from 'components/Dashboard/Outbounds/SKUsSection';
import CommentsSection from 'components/Dashboard/Outbounds/CommentsSection';
import AttachmentsSection from 'components/Dashboard/Orders/AttachmentsSection';
import StatusHistorySection from 'components/Dashboard/Orders/StatusHistorySection';
import {
  cancelOutbound,
  createOutboundRate,
  getOutboundById,
  getOutboundSKUs,
  printPickingList,
  submitOutboundForReview,
  updateOutbound,
} from 'api/Dashboard/outbounds';
import { openPDF } from 'utils/pdf';
import { AbilityContext } from 'context/AbilityContext';
import { useContext, useRef } from 'react';
import ChargeableActivities from 'components/Dashboard/Orders/ChargeableActivities';
import InboundOutboundSkeleton from 'components/Dashboard/Orders/InboundOutboundSkeleton';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import styled from '@emotion/styled';
import RatingModal from 'components/Dashboard/Orders/RatingModal';
import { handleNotFoundPage } from 'utils/notFoundPage';

const StyledEditIcon = styled(EditIcon)`
  fill: #03989e;
  max-width: 20px;
  max-height: 20px;
  path {
    stroke: #fff;
  }

  path:last-of-type {
    stroke: #03989e;
  }
`;

const oneItemStatuses = [
  {
    value: 'created',
    label: 'outbound.outboundStatus.created',
    color: 'purple',
  },
  {
    value: 'in_review',
    label: 'outbound.outboundStatus.in_review',
    color: 'yellow',
  },
  {
    value: 'draft',
    label: 'outbound.outboundStatus.draft',
    color: 'gray',
  },
  {
    value: 'on_hold',
    label: 'orderStatus.on_hold',
    color: 'gray',
  },
  {
    value: 'cancelled',
    label: 'outbound.outboundStatus.cancelled',
    color: 'red',
  },
  {
    value: 'pending_approval',
    label: 'orderStatus.pending_approval',
    color: 'pink',
  },
];

const OutboundDetails = () => {
  const { t } = useTranslation();
  const { pageTitle } = useOutletContext();
  const { id } = useParams();
  const toast = useToast();
  const {
    isOpen: isRatingModalOpen,
    onOpen: onRatingModalOpen,
    onClose: onRatingModalClose,
  } = useDisclosure();

  const isFirstFetch = useRef(true);

  const ability = useContext(AbilityContext);
  const canConfirm = ability.can('confirm', 'outbound');

  const {
    data: outboundDetails = {},
    refetch,
    isLoading,
  } = useQuery(['outbound-details', id], () => getOutboundById(id), {
    onSuccess: (data) => {
      const hasRating = data?.review_rating;
      const isCompleted = data?.status === 'completed';

      if (isFirstFetch.current && !hasRating && isCompleted) {
        onRatingModalOpen();
        isFirstFetch.current = false;
      }
    },
    onError: handleNotFoundPage,
    refetchOnMount: true,
  });

  const {
    data: outboundSKUs = { data: [] },
    refetch: refetchOutboundSKUs,
    isLoading: isOutboundSKUsLoading,
  } = useQuery(['outbound-skus', id], () => getOutboundSKUs(id));

  const { mutate: cancelOutboundMutation, isLoading: isCancelOutboundLoading } =
    useMutation(cancelOutbound, {
      onSuccess: (success) => {
        refetch();
      },
    });

  const { refetch: printPickingListQuery, isLoading: isPrintLoading } =
    useQuery(['print-picking-list', id], () => printPickingList(id), {
      enabled: false,
      onSuccess: (response) => openPDF(response.data),
    });

  const { mutate: confirmOutbound, isLoading: isConfirmOutboundLoading } =
    useMutation(
      async () => {
        await updateOutbound(id, {
          status: 'created',
        });
      },
      {
        onSuccess: () => {
          refetch();
        },
        onError: (error) => {
          const errorMsg = error.response.data.errors.status[0];

          toast({
            title: errorMsg,
            status: 'error',
            duration: 5000,
          });
        },
      }
    );

  const { mutate: submitOutbound, isLoading: isSubmitOutboundLoading } =
    useMutation(() => submitOutboundForReview(id), {
      onSuccess: () => {
        toast({
          title: t('submittedSuccessfully'),
          status: 'success',
          duration: 3000,
        });
        refetch();
      },
    });

  const { mutate: rateOutboundMutation, isLoading: isRateOutboundLoading } =
    useMutation((review) => createOutboundRate(id, review), {
      onSuccess: () => {
        refetch();
        onRatingModalClose();
      },
    });

  const onRateSubmit = (data) => {
    rateOutboundMutation(data);
  };

  const handleCancelOutbound = () => {
    cancelOutboundMutation(id);
  };

  const isDraft = outboundDetails.status === 'draft';

  const activeOneItemStatus = oneItemStatuses.find(
    (item) => item.value === outboundDetails.status
  );

  if (isLoading) {
    return <InboundOutboundSkeleton />;
  }

  return (
    <Stack spacing={6}>
      <Box>
        <Show below="md">
          <Box mb={8} mt={4}>
            <PageTitle title={pageTitle} fontWeight={600} />
          </Box>
        </Show>
        <Flex justifyContent="space-between" flexWrap="wrap">
          <Hide below="md">
            <BackToListButton title={t('outboundsList')} url="/outbounds" />
          </Hide>
          <Flex gap={4} flex={1} justifyContent="flex-end" flexWrap="wrap">
            <Button
              leftIcon={<DownloadIcon />}
              colorScheme="gray"
              textTransform="capitalize"
              size="lg"
              bgColor="white"
              color="gray.600"
              width={{ base: 'full', md: 'auto' }}
              onClick={() => {
                printPickingListQuery();
              }}
              isLoading={isPrintLoading}
            >
              {t('printPickingList')}
            </Button>

            {isDraft && (
              <Button
                size="lg"
                colorScheme="gray"
                bgColor="white"
                color="red.500"
                textTransform="capitalize"
                width={{ base: 'full', md: 'auto' }}
                isLoading={isCancelOutboundLoading}
                onClick={handleCancelOutbound}
                leftIcon={
                  <CloseIcon
                    border="2px solid"
                    borderColor="red.500"
                    borderRadius="full"
                    padding={1}
                    boxSize="20px"
                  />
                }
              >
                {t('cancelOutbound')}
              </Button>
            )}

            {isDraft && (
              <Button
                variant="outline"
                colorScheme="primary"
                backgroundColor="white"
                size="lg"
                width={{ base: 'full', md: 'auto' }}
                leftIcon={<StyledEditIcon />}
                as={Link}
                to="edit"
              >
                {t('editOutbound')}
              </Button>
            )}

            {isDraft && (
              <Button
                colorScheme="primary"
                size="lg"
                minWidth="124px"
                textTransform="capitalize"
                width={{ base: 'full', md: 'auto' }}
                onClick={canConfirm ? confirmOutbound : submitOutbound}
                isDisabled={isOutboundSKUsLoading || !outboundSKUs.data.length}
                isLoading={
                  canConfirm
                    ? isConfirmOutboundLoading
                    : isSubmitOutboundLoading
                }
              >
                {t(canConfirm ? 'confirmOutbound' : 'submitForReview')}
              </Button>
            )}
          </Flex>
        </Flex>
      </Box>

      {!activeOneItemStatus && !isLoading && (
        <StatusSection activeStatus={outboundDetails.status} />
      )}

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
        <Flex gap={6} flexDirection="column">
          <DetailsSection
            outboundDetails={outboundDetails}
            onSuccess={refetch}
            activeOneItemStatus={activeOneItemStatus}
          />
        </Flex>

        <LocationSection outboundDetails={outboundDetails} />
      </SimpleGrid>

      <SKUsSection
        outboundId={id}
        status={outboundDetails.status}
        onUpdateSuccess={refetchOutboundSKUs}
      />

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
        <CommentsSection
          id={id}
          comments={outboundDetails.comments}
          onSuccess={refetch}
        />

        {!isLoading && (
          <AttachmentsSection
            id={id}
            attachments={outboundDetails.attachments}
            onSuccess={refetch}
            attachmentType="Outbound"
          />
        )}
      </SimpleGrid>

      <ChargeableActivities orderId={id} isOutbound />

      <StatusHistorySection
        statusHistories={outboundDetails.status_histories}
      />

      <RatingModal
        isOpen={isRatingModalOpen}
        onClose={onRatingModalClose}
        onSubmit={onRateSubmit}
        isLoading={isRateOutboundLoading}
        isOutbound
      />
    </Stack>
  );
};

export default OutboundDetails;
