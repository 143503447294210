import useTable from 'hooks/useTable';
import { SearchInput } from '../TablePage';
import { useEffect } from 'react';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  HStack,
  ListItem,
  SimpleGrid,
  Text,
  UnorderedList,
  useBoolean,
} from '@chakra-ui/react';
import {
  deliveryTypeEnum,
  truckSizeEnum,
  truckTypeEnum,
} from 'constants/dashboard';
import Table from '../Table';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Select from '../Select';
import { ReactComponent as TruckFastIcon } from 'assets/icons/truck-fast.svg';
import { ReactComponent as LaborerIcon } from 'assets/icons/laborer.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import styled from '@emotion/styled';
import DebouncedNumberInput from 'components/shared/Inputs/DebouncedNumberInput';
import { createColumnHelper } from '@tanstack/react-table';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import { getCarriers } from 'api/Dashboard/transportation';
import { getFormSelectDefaultValue } from 'utils/select';

const StyledEditIcon = styled(EditIcon)`
  path {
    stroke: #03989e;
  }
`;

const StyledTruckFastIcon = styled(TruckFastIcon)`
  path {
    stroke: #4a5568;
  }
`;

const TransportationFormField = ({
  icon,
  label,
  value,
  showInput,
  onChange,
  min,
  errorMessage,
}) => {
  return (
    <Flex
      justifyContent={{ base: 'space-between', md: 'flex-start' }}
      alignItems="center"
      gap={showInput ? 4 : 8}
      fontWeight={500}
    >
      <HStack spacing={2}>
        {icon}
        <Text color="gray.700">{label}</Text>
      </HStack>

      {showInput ? (
        <Box maxWidth={{ base: '90px', md: '100px' }}>
          <DebouncedNumberInput
            defaultValue={value}
            onChange={onChange}
            size="md"
            min={min}
            errorMessage={errorMessage}
          />
        </Box>
      ) : (
        <Text color="gray.900">{value}</Text>
      )}
    </Flex>
  );
};

const transportationFormContainerStyles = {
  paddingBlock: { base: 6, md: 3 },
  paddingInline: { base: 3, md: 6 },
  backgroundColor: 'primary.50',
  marginTop: 6,
  borderRadius: 'lg',
  minHeight: '72px',
  alignItems: 'center',
};

const TransportationForm = ({ defaultValues, onSubmit, showLaborers }) => {
  const { t } = useTranslation();
  const [showInputs, setShowInputs] = useBoolean(true);

  const { handleSubmit, formState, control } = useForm({
    defaultValues,
  });

  const submit = (data) => {
    setShowInputs.off();
    onSubmit(data);
  };

  return (
    <SimpleGrid
      sx={transportationFormContainerStyles}
      as="form"
      onSubmit={handleSubmit(submit)}
      columns={{ base: 1, md: showLaborers ? 3 : 2 }}
      spacing={6}
      noValidate
    >
      <Controller
        control={control}
        name="num_of_trucks"
        rules={{
          required: 'thisFieldIsRequired',
          min: {
            value: 1,
            message: `${t('minIs')} 1`,
          },
        }}
        render={({ field, fieldState }) => (
          <TransportationFormField
            label={t('numberOfTrucks')}
            icon={<StyledTruckFastIcon />}
            value={defaultValues.num_of_trucks}
            onChange={field.onChange}
            showInput={showInputs}
            errorMessage={fieldState.error?.message}
            min={1}
          />
        )}
      />

      {showLaborers && (
        <Controller
          control={control}
          name="num_of_labors"
          rules={{ required: 'thisFieldIsRequired' }}
          render={({ field }) => (
            <TransportationFormField
              label={t('numberOfLaborers')}
              icon={<LaborerIcon />}
              value={defaultValues.num_of_labors}
              onChange={field.onChange}
              showInput={showInputs}
            />
          )}
        />
      )}

      <Flex justifyContent="flex-end">
        {showInputs ? (
          <Flex gap={4} width={{ base: 'full', md: 'auto' }}>
            <Button
              colorScheme="red"
              variant="ghost"
              bgColor="white"
              onClick={setShowInputs.off}
              flex={{ base: 1, md: 'auto' }}
            >
              {t('cancel')}
            </Button>
            <Button
              isDisabled={!formState.isDirty}
              colorScheme="primary"
              type="submit"
              flex={{ base: 1, md: 'auto' }}
            >
              {t('update')}
            </Button>
          </Flex>
        ) : (
          <Button
            onClick={setShowInputs.toggle}
            size="lg"
            leftIcon={<StyledEditIcon />}
            sx={{
              color: 'primary.500',
              colorScheme: 'primary',
              width: { base: 'full', md: 'auto' },
              backgroundColor: 'primary.200',
              _hover: {
                backgroundColor: 'primary.300',
              },
            }}
          >
            {t('editQuantities')}
          </Button>
        )}
      </Flex>
    </SimpleGrid>
  );
};

const columnHelper = createColumnHelper();

const createURLQueryParams = (obj, additionalQueryString) => {
  const params = new URLSearchParams();

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      params.append(key, obj[key]);
    }
  }

  const additionalParams = new URLSearchParams(additionalQueryString);
  for (const [key, value] of additionalParams.entries()) {
    params.append(key, value);
  }

  return params.toString();
};

const PriceCell = ({ price, label }) => {
  const { t } = useTranslation();

  return <Text fontWeight={500}>{`${price} ${t('SAR')} / ${t(label)}`}</Text>;
};

const TransportationRate = ({
  transportationOrderId,
  location,
  defaultValues,
  onSelectCarrier,
  onQuantitySubmit,
  isSelectCarrierLoading,
  onFiltersChange,
}) => {
  const { t } = useTranslation();

  const selectedCarrierId = defaultValues.selectedCarrierId;

  const truckTypeOptions = Object.keys(truckTypeEnum).map((key) => ({
    label: t(`transportationOrderEnums.${key}`),
    value: key,
  }));

  const truckSizeOptions = Object.keys(truckSizeEnum).map((key) => ({
    label: t(`transportationOrderEnums.${key}`),
    value: key,
  }));

  const deliveryTypeOptions = Object.keys(deliveryTypeEnum).map((key) => ({
    label: t(`transportationOrderEnums.${key}`),
    value: key,
  }));

  const filterDefaultValues = {
    truck_type: defaultValues.truck_type,
    truck_size: defaultValues.truck_size,
    delivery_type: defaultValues.delivery_type,
  };

  const { formState, control } = useForm({
    defaultValues: filterDefaultValues,
  });

  const watchTransportationFilters = useWatch({ control });

  const {
    data: carriers = [],
    isLoading: isCarriersLoading,
    isRefetching,
    onSearchChange,
    onPaginationChange,
    onSortingChange,
    pagination,
    refetch: refetchCarriers,
  } = useTable({
    fetch: (tableParams) => {
      if (!location.originId || !location.destinationId) {
        return {};
      }

      const queryParams = createURLQueryParams(
        {
          ...watchTransportationFilters,
          ...(transportationOrderId && {
            transportation_order_id: transportationOrderId,
          }),
          origin_id: location.originId,
          destination_id: location.destinationId,
        },
        tableParams
      );

      return getCarriers(queryParams);
    },
    fetchKey: ['transportation-carriers'],
    searchKey: 'carrier_name_cont',
    disableURLParams: true,
    enabled: false,
  });

  const hasValidFilters = Object.values(watchTransportationFilters).every(
    (value) => value !== undefined && value !== null && value !== ''
  );

  useEffect(() => {
    if (hasValidFilters) {
      refetchCarriers();
    }
  }, [watchTransportationFilters, hasValidFilters, refetchCarriers]);

  useEffect(() => {
    onFiltersChange?.(watchTransportationFilters);
  }, [watchTransportationFilters, onFiltersChange]);

  const actionColumn = columnHelper.accessor('', {
    cell: ({ getValue, row }) => {
      const id = row.original.id;
      const isSelected = selectedCarrierId && selectedCarrierId === id;

      return (
        <Button
          variant="outline"
          color={isSelected ? 'red.600' : 'primary.700'}
          textTransform="capitalize"
          fontWeight={500}
          width={{ base: 'full', md: 'auto' }}
          onClick={() => {
            if (isSelected) {
              onSelectCarrier(null);
              return;
            }

            onSelectCarrier(id);
          }}
          isDisabled={selectedCarrierId && !isSelected}
          isLoading={isSelectCarrierLoading}
        >
          {t(isSelected ? 'unselect' : 'select')}
        </Button>
      );
    },
    header: t('actions'),
    meta: {
      fullWidthAction: true,
    },
    enableSorting: false,
  });

  const columns = [
    columnHelper.accessor('carrier_name', {
      cell: ({ getValue }) => <Text fontWeight={600}>{getValue()}</Text>,
      header: t('carrierName'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('insurance', {
      cell: ({ getValue }) => {
        return <Text fontWeight={500}>{t(!!getValue() ? 'yes' : 'no')}</Text>;
      },
      header: t('insurance'),
      enableSorting: false,
    }),
    columnHelper.accessor('sla', {
      cell: ({ getValue, row }) => {
        const slaList = getValue();

        if (!slaList?.length) {
          return <Text fontWeight={500}>N/A</Text>;
        }

        return (
          <UnorderedList
            sx={{
              fontWeight: 500,
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              paddingLeft: 1,
              maxWidth: '100px',
              whiteSpace: 'wrap ',
            }}
          >
            {slaList?.map((slaText, index) => (
              <ListItem key={`${row.original.id}-sla-${index}`}>
                {slaText}
              </ListItem>
            ))}
          </UnorderedList>
        );
      },
      header: t('sla'),
      enableSorting: false,
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('truck_price', {
      cell: ({ getValue }) => (
        <Center>
          <PriceCell price={getValue()} label="truck" />
        </Center>
      ),
      header: t('truckPrice'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('labor_price', {
      cell: ({ getValue }) => (
        <Center>
          <PriceCell price={getValue()} label="laborer" />
        </Center>
      ),
      header: t('laborerPrice'),
      meta: {
        centerHeader: true,
      },
    }),
    actionColumn,
  ];

  const errors = {
    ...formState.errors,
  };

  const debouncedRefetch = useDebouncedCallback(() => {
    refetchCarriers();
  }, 300);

  const handleSearchChange = (newSearch) => {
    onSearchChange(newSearch);
    debouncedRefetch();
  };

  return (
    <Card size="lg">
      <CardHeader
        display="flex"
        justifyContent="space-between"
        alignItems={{ base: 'flex-end', md: 'center' }}
        flexDirection={{ base: 'column', md: 'row' }}
        gap={{ base: 6, md: 4 }}
      >
        <Box width="full">
          <SectionTitle title={t('transportationDetails')} hideDivider />
        </Box>

        <Flex gap={4} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
          <SearchInput
            placeholder={t('searchByCarrierName')}
            onChange={(e) => {
              handleSearchChange(e.target.value);
            }}
            disabled={!hasValidFilters}
          />
        </Flex>
      </CardHeader>

      <CardBody>
        <Box marginBottom={5}>
          <Text
            marginBottom={6}
            color="primary.700"
            fontSize="lg"
            fontWeight={500}
          >
            {t('pleaseSelectCarrierMessage')}
          </Text>

          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
            <Controller
              control={control}
              name="truck_type"
              rules={{ required: 'thisFieldIsRequired' }}
              render={({ field: { onChange, value } }) => (
                <Select
                  label={t('truckType')}
                  placeholder={t('pleaseSelectTheTruckType')}
                  options={truckTypeOptions}
                  onChange={({ value }) => onChange(value)}
                  defaultValue={getFormSelectDefaultValue(
                    defaultValues.truck_type,
                    truckTypeOptions
                  )}
                  error={errors?.truck_type}
                  size="lg"
                  required
                />
              )}
            />

            <Controller
              control={control}
              name="truck_size"
              rules={{ required: 'thisFieldIsRequired' }}
              render={({ field: { onChange, value } }) => (
                <Select
                  label={t('truckSize')}
                  placeholder={t('pleaseSelectTheTruckSize')}
                  options={truckSizeOptions}
                  onChange={({ value }) => onChange(value)}
                  defaultValue={getFormSelectDefaultValue(
                    defaultValues.truck_size,
                    truckSizeOptions
                  )}
                  error={errors?.truck_size}
                  size="lg"
                  required
                />
              )}
            />

            <Controller
              control={control}
              name="delivery_type"
              rules={{ required: 'thisFieldIsRequired' }}
              render={({ field: { onChange, value } }) => (
                <Select
                  label={t('deliveryType')}
                  placeholder={t('pleaseSelectTheDeliveryType')}
                  options={deliveryTypeOptions}
                  onChange={({ value }) => onChange(value)}
                  defaultValue={getFormSelectDefaultValue(
                    filterDefaultValues.delivery_type,
                    deliveryTypeOptions
                  )}
                  error={errors?.vehicle_type}
                  size="lg"
                  required
                />
              )}
            />
          </SimpleGrid>
        </Box>

        {hasValidFilters && (
          <Table
            data={carriers}
            columns={columns}
            onPaginationChange={(paginationParams) => {
              onPaginationChange(paginationParams);
              debouncedRefetch();
            }}
            onSortingChange={(sortParams) => {
              onSortingChange(sortParams);
              debouncedRefetch();
            }}
            pageCount={pagination.totalPages}
            pageIndex={pagination.pageIndex}
            isLoading={isCarriersLoading || isRefetching}
          />
        )}

        {selectedCarrierId && (
          <Box marginBottom={4}>
            <TransportationForm
              defaultValues={{
                num_of_trucks: defaultValues.trucks,
                num_of_labors: defaultValues.laborers,
              }}
              onSubmit={onQuantitySubmit}
              showLaborers={
                filterDefaultValues.delivery_type === deliveryTypeEnum.dedicated
              }
            />
          </Box>
        )}

        <Text color="gray.700" fontSize="sm" fontWeight={500}>
          {t('carrierSelectionPricesNote')}
        </Text>
      </CardBody>
    </Card>
  );
};

export default TransportationRate;
