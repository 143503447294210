import { createStandaloneToast } from '@chakra-ui/react';
import i18n from './i18n';

const { toast } = createStandaloneToast();

export const showErrorToast = (message) => {
  toast({
    title: i18n.t(message),
    status: 'error',
    duration: 5000,
    isClosable: false,
  });
};
