import api from 'utils/axios';

export const updateOrganization = async (id, organization) => {
  try {
    const response = await api.put(`/organizations/${id}`, { organization });
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};
