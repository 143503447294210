import * as amplitude from '@amplitude/analytics-browser';

const getDeviceIdFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('deviceId');
};

const initAmplitude = () => {
  const deviceId = getDeviceIdFromUrl();
  const initOptions = {
    ...(deviceId && { deviceId }),
    defaultTracking: true,
  };

  amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY, null, initOptions);
};

const setAmplitudeUserData = ({ email, role, company, companyID } = {}) => {
  const identifyEvent = new amplitude.Identify();

  role && identifyEvent.set('Role', role);
  company && identifyEvent.set('Organization', company);
  email && amplitude.setUserId(email);
  companyID && identifyEvent.set('Organization ID', companyID);

  amplitude.identify(identifyEvent);
};

export { initAmplitude, setAmplitudeUserData, amplitude };
