import i18n from 'utils/i18n';

export const inboundStatus = {
  draft: 0,
  created: 1,
  // in_transit: 2,
  received: 3,
  quality_control: 4,
  on_hold: 5,
  put_away: 6,
  complete: 7,
  cancelled: 8,
  in_review: 9,
  arrived_at_warehouse: 10,
  unloading: 11,
  location_assignment: 12,
};

export const outboundStatus = {
  draft: 0,
  created: 1,
  reserved: 2,
  picking: 3,
  ready_for_loading: 4,
  in_transit: 5,
  completed: 6,
  cancelled: 7,
  on_hold: 8,
  loading: 10,
};

export const orderTypeTagColors = {
  CLIENT_DROP_OFF: 'green',
  SIRDAB_PICKUP: 'purple',
  Manifest: 'green',
  Outbound: 'purple',
  ABTrip: 'yellow',
};

export const outboundTypeTagColors = {
  PICKUP_FROM_WAREHOUSE: 'green',
  DELIVERY_SERVICE: 'blue',
};

export const packagingEnum = {
  pallet: 0,
  box: 1,
  // bin: 2, // for internal use only
  unit: 3,
};

export const outboundTypeEnum = {
  DELIVERY_SERVICE: 4,
  PICKUP_FROM_WAREHOUSE: 5,
};

export const inboundTypeEnum = {
  SIRDAB_PICKUP: 1,
  SUPPLIER_DROPOFF: 2,
  CLIENT_DROP_OFF: 3,
};

export const warehouseTypeEnum = {
  dry: 0,
  temperature_controlled: 1,
  chilled: 2,
  frozen: 3,
};

export const getWarehouseTypeOptions = () => [
  {
    label: i18n.t('dry'),
    value: warehouseTypeEnum.dry,
  },
  {
    label: i18n.t('tempControlled'),
    value: warehouseTypeEnum.temperature_controlled,
  },
  {
    label: i18n.t('chilled'),
    value: warehouseTypeEnum.chilled,
  },
  {
    label: i18n.t('frozen'),
    value: warehouseTypeEnum.frozen,
  },
];

export const transportableTypeEnum = {
  Manifest: 'Manifest',
  Outbound: 'Outbound',
  ABTrip: 'ABTrip',
};

export const truckTypeEnum = {
  side: 'side',
  box: 'box',
  chilled: 'chilled',
  frozen: 'frozen',
  flatbed: 'flatbed',
  curtain: 'curtain',
  crane: 'crane',
};

export const truckSizeEnum = {
  cargo_van: 'cargo_van',
  diyanna: 'diyanna',
  loori: 'loori',
  ftl_truck: 'ftl_truck',
};

export const deliveryTypeEnum = {
  dedicated: 'dedicated',
  shared: 'shared',
};

export const agreementTypeEnum = {
  monthly: 0,
  semi_annually: 1,
  annually: 2,
  specific_duration: 3,
};
