import React, { useContext, createContext } from 'react';
import { useQuery } from 'react-query';
import { getFeatureFlags } from 'api/featureFlags';
import useAuth from 'hooks/useAuth';
import { getLocalStorage } from 'utils/localStorage';

const FeatureFlagsContext = createContext();

export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagsContext);

  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }

  const isEnabled = (feature, defaultValue = false) => {
    return context.features[feature] ?? defaultValue;
  };

  return { isEnabled };
};

const FeatureFlagsProvider = ({ children }) => {
  const { token } = useAuth();
  const user = getLocalStorage('user');

  const { data, isLoading } = useQuery(['feature-flags'], getFeatureFlags, {
    enabled: !!token && !!user?.otp_verified_at,
  });

  return (
    <FeatureFlagsContext.Provider
      value={{ features: data?.features ?? {} }}
      loading={isLoading}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export default FeatureFlagsProvider;
