import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import CalendlyPopupText from 'components/shared/CalendlyPopupText';

const AccountLockedContent = ({ textAlign = 'left', title, subtitle }) => {
  return (
    <Stack
      textAlign={textAlign}
      fontFamily={textAlign === 'left' ? 'Inter Variable' : 'Almarai'}
      spacing={4}
    >
      <Text fontSize="3xl" fontWeight={600}>
        {title}
      </Text>
      <Text fontWeight={500} color="gray.600">
        {subtitle}
      </Text>
    </Stack>
  );
};

const AccountLockedModal = ({ isOpen }) => {
  return (
    <Modal isOpen={isOpen} size="3xl" isCentered>
      <ModalOverlay />
      <ModalContent padding={10} paddingBottom={6}>
        <ModalBody>
          <Stack spacing={16}>
            <AccountLockedContent
              title="Your account has been locked"
              subtitle="We are sorry to inform you that your account access has been paused due to overdue invoices. To resume access to our warehousing services, please settle all pending invoices."
            />
            <AccountLockedContent
              title="حسابك موقف بالوقت الحالي"
              subtitle="يؤسفنا إبلاغك بأنه تم إيقاف حسابك بسبب الفواتير المتأخرة. لإستعادة الوصول الى حسابك، يرجى سداد جميع الفواتير المعلقة."
              textAlign="right"
            />
          </Stack>
        </ModalBody>

        <ModalFooter justifyContent="center" marginTop={8}>
          <CalendlyPopupText
            content={
              <Button as="span" colorScheme="primary" size="lg">
                Schedule a call with our finance team
              </Button>
            }
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AccountLockedModal;
