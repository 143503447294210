import { Box, Flex, Hide, Show, Stack } from '@chakra-ui/react';
import {
  getOutboundById,
  requestNewOutbound,
  updateOutbound,
} from 'api/Dashboard/outbounds';
import BackToListButton from 'components/Dashboard/BackToListButton';
import OutboundForm from 'components/Dashboard/Outbounds/OutboundForm';
import PageTitle from 'components/Layouts/DashboardLayout/PageTitle';
import { outboundTypeEnum } from 'constants/dashboard';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { adjustToUTC, utcStringToDate } from 'utils/date';
import { handleNotFoundPage } from 'utils/notFoundPage';
import { mapSelectListToFormOptions } from 'utils/select';

const OutboundEdit = () => {
  const { t } = useTranslation();
  const { pageTitle } = useOutletContext();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data = {}, isLoading: isRequestNewOutboundLoading } = useQuery(
    ['request-new-outbound'],
    requestNewOutbound
  );

  const { data: outboundDetails = {}, isLoading: isOutboundDetailsLoading } =
    useQuery(['outbound-details', id], () => getOutboundById(id), {
      onError: handleNotFoundPage,
      refetchOnMount: true,
      cacheTime: 0,
    });

  const { mutate: updateOutboundMutation, isLoading: isUpdateOutboundLoading } =
    useMutation((outbound) => updateOutbound(id, outbound), {
      onSuccess: (success) => {
        navigate(`/outbounds/${id}/assign`);
      },
    });

  if (isRequestNewOutboundLoading || isOutboundDetailsLoading) {
    return null;
  }

  const outboundTypeOptions = data.select_options?.outbound_types.map(
    ([label, value]) => {
      const labelKey = Object.keys(outboundTypeEnum).find(
        (key) => outboundTypeEnum[key] === value
      );

      return {
        label: t(`orderCarrier.${labelKey}`),
        value,
      };
    }
  );

  const destinationOptions = mapSelectListToFormOptions(
    data.select_options?.destinations
  );

  const warehouseOptions = data.select_options?.warehouses.map((warehouse) => ({
    label: warehouse.name,
    value: warehouse.address_id,
    id: warehouse.id,
  }));

  const defaultValues = {
    carrier_id: outboundTypeEnum[outboundDetails.outbound_type],
    origin_id: outboundDetails.origin?.id,
    destination_id: outboundDetails.destination?.id,
    schedule_at: utcStringToDate(outboundDetails.schedule_at),
    supplier_id: outboundDetails.supplier_id,
    time_of_pickup: outboundDetails.time_of_pickup,
  };

  const handleSubmit = (data) => {
    const payload = {
      ...data,
      schedule_at: adjustToUTC(data.schedule_at),
      time_of_pickup: data.time_of_pickup.value,
      urgent: data.time_of_pickup.urgent,
    };

    updateOutboundMutation(payload);
  };

  return (
    <Stack spacing={6}>
      <Box>
        <Show below="md">
          <Box mb={2} mt={4}>
            <PageTitle title={pageTitle} fontWeight={600} />
          </Box>
        </Show>
        <Flex justifyContent="space-between" flexWrap="wrap">
          <Hide below="md">
            <BackToListButton title={t('outboundsList')} url="/outbounds" />
          </Hide>
        </Flex>
      </Box>

      <OutboundForm
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        outboundTypeOptions={outboundTypeOptions}
        destinationOptions={destinationOptions}
        warehouseOptions={warehouseOptions}
        isLoading={isUpdateOutboundLoading}
        isEdit
      />
    </Stack>
  );
};

export default OutboundEdit;
